import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  QcPermissionCategory,
  TbqGlobalScope,
  useGetTbqGlobalScopeLazyQuery,
  useSetTbqConfigMutation,
} from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Growthbook } from '@pro4all/shared/vendor';

import { Banner } from '../view/detail/Banners.styles';
import { useObjectDetailContext } from '../view/detail/ObjectDetailContext';

import { ScanCard } from './scans/ScanCard';
import { Scans } from './scans/Scans.styles';
import { TBQSettings } from './settings';
import { TBQCreateScanSidePanel } from './tasks';

const TBQBanner = styled(Banner)`
  background-color: #cdf3ed;
  flex-wrap: wrap;
`;

export const TBQType = () => {
  const { searchParams } = useRouting();
  const createBCScan = () => searchParams.set('action', 'createBrandcheckScan');
  const createRIEScan = () => searchParams.set('action', 'createRIEScan');
  const hasBrandcheckFeatureFlag = useFeatureFlag('tbq.brandcheck');
  const hasRieFeatureFlag = useFeatureFlag('tbq.rie');

  const {
    object: { id: objectId, tbqType, tbqScanTypeAllowed, project, projectId },
  } = useObjectDetailContext();
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');
  const { tbqscanCreateAll, tbqscanCreateOwn, tbqscanCreateNone } =
    useQCPermissions({
      category: QcPermissionCategory.Procedure,
      qcGroupPermissionsFlag,
    });

  const createAllowed: boolean = tbqscanCreateAll || tbqscanCreateOwn;

  const [setTbqConfig] = useSetTbqConfigMutation();

  const createModuleScan = (scopeId: string) => {
    searchParams.set('action', 'createModuleScan');
    searchParams.set('scopeId', scopeId);
  };

  const [getScopes] = useGetTbqGlobalScopeLazyQuery();
  useEffect(() => {
    const asyncScopes = async () => {
      const response = await getScopes();
      const empty: TbqGlobalScope[] = [];
      const result: TbqGlobalScope[] =
        response?.data?.getTBQGlobalScope?.map((x) => ({
          name: x?.name,
          scopeId: x?.scopeId,
        })) ?? empty;
      setScopes(result);
    };
    asyncScopes();
  }, [getScopes]);
  const [scopes, setScopes] = useState<TbqGlobalScope[]>([]);
  const featuredScopes = scopes.filter((s) =>
    Growthbook.featureIsOn(`tbqmodule.${s.scopeId}`)
  );

  const { t } = useTranslation();

  function getBrandcheckCardData() {
    let brandCheckCreateTooltip: string | undefined = undefined;
    if (tbqScanTypeAllowed && tbqScanTypeAllowed.length > 0)
      brandCheckCreateTooltip = undefined;
    else if (!tbqType)
      brandCheckCreateTooltip = t('Missing brandcheck configuration');
    else if (!tbqScanTypeAllowed?.length)
      brandCheckCreateTooltip = t('Check configuration on child object(s)');
    const brandCheckViewSettings = () =>
      searchParams.set('action', 'tbqSettings');
    const brandCheckDeleteSettings = !tbqType
      ? null
      : () => setTbqConfig({ variables: { nodeId: objectId } });

    return {
      brandCheckCreateTooltip,
      brandCheckDeleteSettings,
      brandCheckViewSettings,
    };
  }

  const {
    brandCheckCreateTooltip,
    brandCheckDeleteSettings,
    brandCheckViewSettings,
  } = getBrandcheckCardData();

  let createTooltip: string | undefined = undefined;
  if (!projectId)
    createTooltip = t('This object is not linked to any project.');
  else if (project == null)
    createTooltip = t('The object is linked to a deleted project');
  else if (!tbqscanCreateAll)
    createTooltip = t('No project permissions to start check');

  return (
    <>
      <TBQBanner>
        <Scans>
          {hasBrandcheckFeatureFlag && (
            <ScanCard
              createEnabled={createAllowed && !brandCheckCreateTooltip}
              createTooltip={createTooltip ?? brandCheckCreateTooltip}
              deleteSettings={brandCheckDeleteSettings}
              onCreate={createBCScan}
              projectId={projectId}
              scanName="Brandcheck"
              settingsDisabled={tbqscanCreateNone}
              viewSettings={brandCheckViewSettings}
            />
          )}
          {hasRieFeatureFlag && (
            <ScanCard
              createEnabled={!createTooltip}
              createTooltip={createTooltip}
              onCreate={createRIEScan}
              scanName="RI&E"
            />
          )}
          {featuredScopes.map((x) => (
            <ScanCard
              createEnabled={!createTooltip}
              createTooltip={createTooltip}
              key={x.scopeId}
              onCreate={() => createModuleScan(x?.scopeId ?? 'empty')}
              scanName={x?.name ?? '?'}
            />
          ))}
        </Scans>
      </TBQBanner>

      <TBQCreateScanSidePanel scopes={scopes} />
      <TBQSettings />
    </>
  );
};
